import { useId } from 'react';

import { breakpoints } from '@hultafors/shared/constants';

import {
  ContactPagePlugFragment,
  ContentListPagePlugFragment,
  ContentPagePlugFragment,
  ContentPlugsBlockFragment,
  FindRetailersPagePlugFragment,
  isContactPagePlug,
  isContentListPagePlug,
  isContentPagePlug,
  isFindRetailersPagePlug,
  isPerson,
  PersonFragment,
} from '@hultafors/snickers/types';

import { CertificatePlug } from '../CertificatePlug/CertificatePlug';
import { GenericPlug } from '../GenericPlug/GenericPlug';

import { StyledPlugsGrid, Title } from './content-plugs-block.styled';

export type ContentPlugsBlockProps = ContentPlugsBlockFragment;

export const ContentPlugsBlock: React.FC<ContentPlugsBlockProps> = ({
  list,
  title,
  threeColumns,
}) => {
  const uid = useId();

  const plugsMapper = (
    item:
      | ContentListPagePlugFragment
      | ContentPagePlugFragment
      | FindRetailersPagePlugFragment
      | ContactPagePlugFragment,
  ) => {
    const key = `ContentPlugsBlock-${uid}-${item.id}`;
    const sizes = [
      `(min-width: ${breakpoints.desktopMedium}) 25vw`,
      `(min-width: ${breakpoints.largeMobile}) 50vw`,
      '100vw',
    ].join(', ');

    if (isFindRetailersPagePlug(item)) {
      const url = '/find-retailers';

      return (
        <GenericPlug
          key={key}
          title={item.teaserTitle}
          label={item.label}
          intro={item.teaserDescription}
          url={url}
          image={item.imageLoad}
          sizes={sizes}
          aspectRatio={threeColumns ? 1 : 3 / 4}
        />
      );
    }

    if (isContentListPagePlug(item)) {
      const url =
        '/' +
        ['list', item.slug].map((part) => part?.replace(/\//g, '')).join('/');

      return (
        <GenericPlug
          key={key}
          title={item.teaserTitle}
          label={item.label}
          intro={item.teaserDescription}
          url={url}
          image={item.imageLoad}
          sizes={sizes}
          aspectRatio={threeColumns ? 1 : 3 / 4}
        />
      );
    }
    if (isContactPagePlug(item)) {
      const url = '/contact'.replace(/\/\//g, '/');

      return (
        <GenericPlug
          key={key}
          title={item.teaserTitle}
          label={item.label}
          intro={item.teaserDescription}
          url={url}
          image={item.imageLoad}
          sizes={sizes}
          aspectRatio={threeColumns ? 1 : 3 / 4}
        />
      );
    }
    if (isContentPagePlug(item)) {
      const person = item?.contentArea?.find((record) => isPerson(record)) as
        | PersonFragment
        | undefined;
      let url =
        '/' +
        ['list', item.slug].map((part) => part?.replace(/\//g, '')).join('/');
      if (item.parentPageSlug) {
        url =
          '/' +
          ['list', item.parentPageSlug, item.slug]
            .map((part) => part?.replace(/\//g, ''))
            .join('/');
      }

      if (item.parentPageSlug?.replace(/\//g, '') === 'protective-standards') {
        const ingress = item.teaserDescription || item.seo?.description;
        const title =
          item.teaserTitle ||
          item.seo?.title?.replace(/\s*\?\s+Snickers Workwear\s*$/i, '');

        return (
          <CertificatePlug
            key={key}
            title={title}
            ingress={ingress}
            image={item.iconImage}
            url={url}
          />
        );
      }
      const intro = item.teaserDescription ?? '';
      const plugTitle = item.teaserTitle ?? '';

      return (
        <GenericPlug
          key={key}
          title={plugTitle}
          label={person?.interviewLabel ?? item.label}
          intro={intro}
          url={url}
          image={item.imageLoad ?? item.seo?.imageLoad}
          name={person?.name}
          sizes={sizes}
          aspectRatio={threeColumns ? 1 : 3 / 4}
        />
      );
    }
    return null;
  };
  return (
    <section>
      {title && <Title>{title}</Title>}
      <StyledPlugsGrid threeColumns={threeColumns}>
        {list?.map(plugsMapper)}
      </StyledPlugsGrid>
    </section>
  );
};
